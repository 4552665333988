<template>
  <div class="left-container">
    <div class="entity-status-drigger">
      <div
        @click="changeEntityType('marker')"
        :class="[
          'entity-type',
          currentEntityType == 'marker' ? 'current-entity-type' : '',
        ]"
      >
        点
      </div>
      <div
        @click="changeEntityType('line')"
        :class="[
          'entity-type',
          currentEntityType == 'line' ? 'current-entity-type' : '',
        ]"
      >
        线条
      </div>
      <div
        @click="changeEntityType('polygon')"
        :class="[
          'entity-type',
          currentEntityType == 'polygon' ? 'current-entity-type' : '',
        ]"
      >
        图形
      </div>
      <!-- 选中下划线 -->
      <div
        :style="{
          left:
            currentEntityType == 'marker'
              ? 8 + 37.33 + 'px'
              : currentEntityType == 'line'
              ? 8 + 37.33 + 74.66 + 'px'
              : 8 + 37.33 + 74.66 * 2 + 'px',
        }"
        class="under-line"
      ></div>
    </div>
    <div class="entity-list-box">
      <div
        :style="{
          left: `-${contentLeftMove}px`,
        }"
        class="entity-list-inner-box"
      >
        <!-- 点 -->
        <div class="entity-item-list-box">
          <scrollBox>
            <div class="entity-list">
              <div v-for="point in markers" :key="point.id" class="list-item">
                <span class="point-icon iconfont">&#xe6d2;</span>
                <span class="marker-name">
                  {{ point.name }}
                </span>
                <span
                  @click="deleteData($event, point)"
                  title="删除"
                  class="close-icon"
                >
                  <Icon color="red" type="md-trash"></Icon>
                </span>
              </div>
            </div>
          </scrollBox>
        </div>
        <!-- 线 -->
        <div class="entity-item-list-box">
          <scrollBox>
            <div class="entity-list">
              <div
                @click="() => checkItem(point)"
                v-for="point in lines"
                :key="point.id"
                class="list-item"
              >
                <span class="point-icon iconfont">&#xec1f;</span>
                <span class="marker-name">
                  {{ point.name }}
                </span>
                <span
                  @click="deleteData($event, point)"
                  title="删除"
                  class="close-icon"
                >
                  <Icon color="red" type="md-trash"></Icon>
                </span>
              </div>
            </div>
          </scrollBox>
        </div>
        <!-- 形状 -->
        <div class="entity-item-list-box">
          <scrollBox>
            <div class="entity-list">
              <div
                @click="() => checkItem(point)"
                v-for="point in polygons"
                :key="point.id"
                class="list-item"
              >
                <span v-if="point.type == 'polygon'" class="point-icon iconfont"
                  >&#xe6d4;</span
                ><span v-if="point.type == 'circle'" class="point-icon iconfont"
                  >&#xe6d1;</span
                >
                <span class="marker-name">
                  {{ point.name }}
                </span>
                <span
                  @click="deleteData($event, point)"
                  title="删除"
                  class="close-icon"
                >
                  <Icon color="red" type="md-trash"></Icon>
                </span>
              </div>
            </div>
          </scrollBox>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import scrollBox from "@/components/scrollBox";
export default {
  props: {
    value: Object,
    markers: {
      type: Array,
      default() {
        return [];
      },
    },
    lines: {
      type: Array,
      default() {
        return [];
      },
    },
    polygons: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    scrollBox,
  },
  data() {
    return {
      currentEntityType: "marker",
      // 当前选中数据
      // current: null,
    };
  },
  computed: {
    contentLeftMove() {
      return this.currentEntityType == "marker"
        ? 0
        : this.currentEntityType == "line"
        ? 240
        : 480;
    },
  },
  methods: {
    // 删除
    deleteData(e, data) {
      e.stopPropagation();
      this.$emit("deleteData", data);
    },
    // 列表点击
    checkItem(data) {
      if (this.value) this.value.data.disableEditing();
      // this.current = data;
      data.data.enableEditing();
      this.$emit("input", data);
    },
    // 更改设备类型
    changeEntityType(type) {
      // if (this.current) this.current.data.disableEditing();
      // this.current = null;
      this.currentEntityType = type;
      this.$emit("check-item");
    },
  },
  mounted() {},
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>