<template>
  <div class="map-page-container">
    <div ref="map" class="map-container"></div>
    <div class="map-search-span">
        <input placeholder="输入位置检索" ref='search' icon='mad-search'></input>
    </div>
    <!-- <div id='selectbox_Drawing'>
    </div> -->
    <div class="draw-options">
      <div @click="triggerExpand" :style="{transform:isExpand?'rotate(0deg)':'rotate(-90deg)'}" :title="isExpand?'收起':'展开'" class="shrink-button"><Icon size='20' color='#fff' type="ios-arrow-forward" /></div>
      <div :style="{width:isExpand?'360px':'0px',paddingLeft:isExpand?'20px':'0px'}" class="shrink-container">
        <div class="options-container">
          <div @click="startDraw('point')" :class="['iconfont',currentDrawType=='point'?'current-draw-type':'']" title="点" >
            &#xe6d2;
          </div>
          <div title="线" @click="startDraw('line')" :class="['iconfont',currentDrawType=='line'?'current-draw-type':'']">
            &#xec1f;
          </div>
          <!-- <div title="矩形" @click="startDraw('ractangle')" :class="['iconfont',currentDrawType=='ractangle'?'current-draw-type':'']">
            &#xe6d3;
          </div> -->
          <div title="多边形" @click="startDraw('polygon')" :class="['iconfont',currentDrawType=='polygon'?'current-draw-type':'']">
            &#xe6d4;
          </div>
          <div title="圆形" @click="startDraw('circle')" :class="['iconfont',currentDrawType=='circle'?'current-draw-type':'']">
            &#xe6d1;
          </div>
          <div class="color-select">
            <span>线条</span>
            <span><ColorPicker v-model="lineColor" size='small' transfer alpha></ColorPicker></span>
          </div>
          <div  class="color-select">
            <span>填充</span>
            <span><ColorPicker v-model="fillColor" size='small' transfer alpha></ColorPicker></span>
          </div>
        </div>
      </div>
    </div>
    <overlayList @deleteData="deleteData" v-model="editingOverlayData"  @edit="editOverlay" :lines='lines' :polygons="polygons" :markers="points"></overlayList>
  </div>
</template>
<script>
import overlayList from "./rightList";
import CU from "@/common/util";

const BMAP_DRAWING_MARKER = "marker", // 鼠标画点模式
  BMAP_DRAWING_POLYLINE = "polyline", // 鼠标画线模式
  BMAP_DRAWING_CIRCLE = "circle", // 鼠标画圆模式
  BMAP_DRAWING_RECTANGLE = "rectangle", // 鼠标画矩形模式
  BMAP_DRAWING_POLYGON = "polygon"; // 鼠标画多边形模式

const labelOptions = {
  borderRadius: "2px",
  background: "#FFFBCC",
  border: "1px solid #E1E1E1",
  color: "#703A04",
  fontSize: "12px",
  letterSpacing: "0",
  padding: "5px",
};
export default {
  components: {
    overlayList,
  },
  props: {
    mapPosition: [String, Array, Object],
    backgroundPosition: [String, Array, Object],
  },
  data() {
    return {
      mapInstance: null,
      searchInput: null,
      drawingManager: null,
      // 当前最高层级
      zindex: 1,
      // 绘制面板展开状态
      isExpand: true,
      // 当前正在绘制的类型
      currentDrawType: "",
      // 点集合
      points: [],
      // 线集合
      lines: [],
      //面集合(包含 矩形，多边形,圆)
      polygons: [],

      // 线条颜色
      lineColor: "rgb(94, 135, 219)",
      // 填充颜色
      fillColor: "rgb(224, 212, 74)",

      // 正在编辑的覆盖物
      editingOverlayData: null,

      // 新备注
      newRemark: "",
    };
  },
  computed: {
    styleOptions() {
      let strokeColor, strokeOpacity, fillColor, fillOpacity;
      try {
        strokeColor = CU.colorRGB2Hex(this.lineColor).hex;
        strokeOpacity = CU.colorRGB2Hex(this.lineColor).opacity;
        fillColor = CU.colorRGB2Hex(this.fillColor).hex;
        fillOpacity = CU.colorRGB2Hex(this.fillColor).opacity;
        return {
          strokeWidht: 2,
          strokeColor,
          strokeOpacity,
          fillColor,
          fillOpacity,
        };
      } catch (e) {
        console.log(e);
        return {
          strokeWidht: 2,
        };
      }
    },
  },
  watch: {
    editingOverlayData() {
      this.editOverlay();
    },
    styleOptions(style) {
      if (this.editingOverlayData) {
        this.editingOverlayData.data.setStrokeColor(style.strokeColor);
        this.editingOverlayData.data.setStrokeOpacity(style.strokeOpacity);
        this.editingOverlayData.data.setFillColor(style.fillColor);
        this.editingOverlayData.data.setFillOpacity(style.fillOpacity);
      } else {
        this.drawingManager.setPolygonOptions(style);
        this.drawingManager.setCircleOptions(style);
        this.drawingManager.setPolylineOptions(style);
        this.drawingManager.setRectangleOptions(style);
      }
    },
  },
  methods: {
    // 加载背景地图
    initBackgroundPosition() {
      if (!this.backgroundPosition) return;
      let data =
        typeof this.backgroundPosition == "string"
          ? JSON.parse(this.backgroundPosition)
          : this.backgroundPosition;
      if (!data || !Array.isArray(data)) return;
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (!this.mapPosition&&i === 0 && item.zoom) {
          setTimeout(() => {
            this.mapInstance.flyTo(
              new BMapGL.Point(
                JSON.parse(item.options.center).lng,
                JSON.parse(item.options.center).lat
              ),
              +item.zoom
            );
          }, 1 * 1000);
        }
        switch (item.type) {
          case "marker":
            let center1 = new BMapGL.Point(
              JSON.parse(item.options.center).lng,
              JSON.parse(item.options.center).lat
            );
            let marker = new BMapGL.Marker(center1);
            this.mapInstance.addOverlay(marker);
            break;
          case "line":
            let path1 = JSON.parse(item.options.path).map(
              (item) => new BMapGL.Point(item.lng, item.lat)
            );
            let polyline = new BMapGL.Polyline(path1, {
              strokeWeight: item.options.strokeWeight,
              strokeColor: item.options.strokeColor,
              strokeOpacity: item.options.strokeOpacity,
            });
            this.mapInstance.addOverlay(polyline);
            break;
          case "polygon":
            let path = JSON.parse(item.options.path).map(
              (item) => new BMapGL.Point(item.lng, item.lat)
            );
            let polygon = new BMapGL.Polygon(path, {
              strokeWeight: +item.options.strokeWeight,
              strokeColor: item.options.strokeColor,
              strokeOpacity: +item.options.strokeOpacity,
              fillColor: item.options.fillColor,
              fillOpacity: +item.options.fillOpacity,
            });
            this.mapInstance.addOverlay(polygon);
            break;
          case "circle":
            let center = new BMapGL.Point(
              JSON.parse(item.options.center).lng,
              JSON.parse(item.options.center).lat
            );
            let circle = new BMapGL.Circle(center, item.options.radius, {
              strokeWeight: item.options.strokeWeight,
              strokeColor: item.options.strokeColor,
              strokeOpacity: item.options.strokeOpacity,
              fillColor: item.options.fillColor,
              fillOpacity: item.options.fillOpacity,
            });
            this.mapInstance.addOverlay(circle);
            break;
        }
      }
    },
    // 初始化覆盖物
    initOverlays() {
      if (!this.mapPosition) return;
      let data =
        typeof this.mapPosition == "string"
          ? JSON.parse(this.mapPosition)
          : this.mapPosition;
      if (!data || !Array.isArray(data)) return;
      this.zIndex = data[data.length - 1].zIndex || 1;
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (i === 0 && item.zoom) {
          setTimeout(() => {
            this.mapInstance.flyTo(
              new BMapGL.Point(
                JSON.parse(item.options.center).lng,
                JSON.parse(item.options.center).lat
              ),
              +item.zoom
            );
          }, 1 * 1000);
        }
        switch (item.type) {
          case "marker":
            let center1 = new BMapGL.Point(
              JSON.parse(item.options.center).lng,
              JSON.parse(item.options.center).lat
            );
            let marker = new BMapGL.Marker(center1);
            this.mapInstance.addOverlay(marker);
            this.points.push({
              ...item,
              id: Math.floor(Math.random() * 100000).toString(),
              data: marker,
            });
            break;
          case "line":
            let path1 = JSON.parse(item.options.path).map(
              (item) => new BMapGL.Point(item.lng, item.lat)
            );
            let polyline = new BMapGL.Polyline(path1, {
              strokeWeight: item.options.strokeWeight,
              strokeColor: item.options.strokeColor,
              strokeOpacity: item.options.strokeOpacity,
            });
            this.mapInstance.addOverlay(polyline);
            this.lines.push({
              ...item,
              id: Math.floor(Math.random() * 100000).toString(),
              data: polyline,
            });
            break;
          case "polygon":
            let path = JSON.parse(item.options.path).map(
              (item) => new BMapGL.Point(item.lng, item.lat)
            );
            let polygon = new BMapGL.Polygon(path, {
              strokeWeight: +item.options.strokeWeight,
              strokeColor: item.options.strokeColor,
              strokeOpacity: +item.options.strokeOpacity,
              fillColor: item.options.fillColor,
              fillOpacity: +item.options.fillOpacity,
            });
            this.mapInstance.addOverlay(polygon);
            this.polygons.push({
              ...item,
              id: Math.floor(Math.random() * 100000).toString(),
              data: polygon,
            });
            break;
          case "circle":
            let center = new BMapGL.Point(
              JSON.parse(item.options.center).lng,
              JSON.parse(item.options.center).lat
            );
            let circle = new BMapGL.Circle(center, item.options.radius, {
              strokeWeight: item.options.strokeWeight,
              strokeColor: item.options.strokeColor,
              strokeOpacity: item.options.strokeOpacity,
              fillColor: item.options.fillColor,
              fillOpacity: item.options.fillOpacity,
            });
            this.mapInstance.addOverlay(circle);
            this.polygons.push({
              ...item,
              id: Math.floor(Math.random() * 100000).toString(),
              data: circle,
            });
            break;
        }
      }
    },
    // 获取覆盖物数据
    getCurrentFeatures() {
      let total = [...this.points, ...this.lines, ...this.polygons].sort(
        (a, b) => a.zIndex - b.zIndex
      );
      if (total.length == 0) return "";
      let result = [];
      for (let i = 0; i < total.length; i++) {
        let item = total[i];
        let temp = {
          type: item.type,
          zIndex: item.zIndex,
          name: item.name,
          zoom: item.zoom,
        };
        let options = {};
        switch (item.type) {
          case "marker":
            options.center = options.point = JSON.stringify(
              item.data.getPosition()
            );
            break;
          case "line":
            options.path = JSON.stringify(item.data.getPath());
            options.strokeWeight = item.data.getStrokeWeight();
            options.strokeColor = item.data.getStrokeColor();
            options.strokeOpacity = item.data.getStrokeOpacity();
            options.center = JSON.stringify(item.data.getBounds().getCenter());
            break;
          case "circle":
            options.center = JSON.stringify(item.data.getCenter());
            options.radius = item.data.getRadius();
            options.strokeWeight = item.data.getStrokeWeight();
            options.strokeColor = item.data.getStrokeColor();
            options.strokeOpacity = item.data.getStrokeOpacity();
            options.fillColor = item.data.getFillColor();
            options.fillOpacity = item.data.getFillOpacity();
            break;
          case "polygon":
            options.path = JSON.stringify(item.data.getPath());
            options.strokeWeight = item.data.getStrokeWeight();
            options.strokeColor = item.data.getStrokeColor();
            options.strokeOpacity = item.data.getStrokeOpacity();
            options.fillColor = item.data.getFillColor();
            options.fillOpacity = item.data.getFillOpacity();
            options.center = JSON.stringify(item.data.getBounds().getCenter());
            break;
          default:
            continue;
        }

        temp.options = options;
        result.push(temp);
      }
      return result;
    },
    // 删除覆盖物
    deleteData(data) {
      if (this.editingOverlayData && this.editingOverlayData.id == data.id) {
        this.editingOverlayData.data.disableEditing();
        this.editingOverlayData = null;
      }
      this.mapInstance.removeOverlay(data.data);
      let index;
      switch (data.type) {
        case "marker":
          index = this.points.findIndex((item) => item.id == data.id);
          if (index !== -1) {
            this.points.splice(index, 1);
          }
          break;
        case "line":
          index = this.lines.findIndex((item) => item.id == data.id);
          if (index !== -1) {
            this.lines.splice(index, 1);
          }
          break;

        default:
          index = this.polygons.findIndex((item) => item.id == data.id);
          if (index !== -1) {
            this.polygons.splice(index, 1);
          }
          break;
      }
    },
    // 编辑覆盖物
    editOverlay() {
      if (this.editingOverlayData) {
        if (this.drawingManager._isOpen) {
          this.drawingManager.close();
          this.currentDrawType = "";
        }
        switch (this.editingOverlayData.type) {
          case "line":
            this.lineColor = CU.hexToRgba(
              this.editingOverlayData.data.getStrokeColor(),
              this.editingOverlayData.data.getStrokeOpacity()
            ).rgba;
            break;
          case "polygon":
            this.lineColor = CU.hexToRgba(
              this.editingOverlayData.data.getStrokeColor(),
              this.editingOverlayData.data.getStrokeOpacity()
            ).rgba;
            this.fillColor = CU.hexToRgba(
              this.editingOverlayData.data.getFillColor(),
              this.editingOverlayData.data.getFillOpacity()
            ).rgba;
            break;
        }
      }
    },
    // 开始绘制
    startDraw(type) {
      this.editingOverlayData && this.editingOverlayData.data.disableEditing();
      this.editingOverlayData = null;
      this.currentDrawType = type;
      let drawType;
      switch (type) {
        case "point":
          drawType = BMAP_DRAWING_MARKER;
          break;
        case "line":
          drawType = BMAP_DRAWING_POLYLINE;
          break;
        case "ractangle":
          drawType = BMAP_DRAWING_RECTANGLE;
          break;
        case "polygon":
          drawType = BMAP_DRAWING_POLYGON;
          break;
        case "circle":
          drawType = BMAP_DRAWING_CIRCLE;
          break;
      }
      if (
        this.drawingManager._isOpen &&
        this.drawingManager.getDrawingMode() == drawType
      ) {
        this.drawingManager.close();
        this.currentDrawType = "";
      } else {
        this.drawingManager.setDrawingMode(drawType);
        this.drawingManager.open();
      }
    },

    // 切换绘制面板展开状态
    triggerExpand() {
      this.isExpand = !this.isExpand;
    },
    initMap() {
      return new Promise((resolve) => {
        this.mapInstance = new BMapGL.Map(this.$refs.map, {
          mapType: BMAP_EARTH_MAP,
        });
        this.mapInstance.centerAndZoom(
          new BMapGL.Point(105.52314042411466, 29.475651579673745),
          11.5
        );
        this.mapInstance.enableScrollWheelZoom(true);
        resolve();
      });
    },
    // 添加行政区
    addBoundary() {
      let boundary = new BMapGL.Boundary();
      boundary.get("重庆市荣昌区", (points) => {
        if (!points) return;
        // 荣昌
        for (let i = 0; i < points.boundaries.length; i++) {
          let tempPoint = points.boundaries[i];
          let tempPoints = tempPoint.split(";").map((item) => {
            let datas = item.split(",");
            return new BMapGL.Point(+datas[0], +datas[1]);
          });
          let polygon = new BMapGL.Polygon(tempPoints, {
            strokeWeight: 2,
            strokeColor: "#06BAA1",
            strokeStyle: "solid",
            fillColor: "blue",
            fillOpacity: 0,
          });
          this.mapInstance.addOverlay(polygon);
        }
      });
    },
    // 添加地址搜索栏
    addSearch() {
      this.searchInput = new BMapGL.Autocomplete({
        location: this.mapInstance,
        input: this.$refs.search,
      });
      this.localSearch = new BMapGL.LocalSearch(this.mapInstance, {
        onSearchComplete: () => {
          let point = this.localSearch.getResults().getPoi(0).point;
          point && this.mapInstance.flyTo(point, 17);
        },
      });
      this.searchInput.addEventListener("onconfirm", (e) => {
        let _value = e.item.value;
        let searchStr =
          _value.province +
          _value.city +
          _value.district +
          _value.street +
          _value.business;
        this.localSearch.search(searchStr);
      });
    },
    // 初始化绘图工具
    initDrawTool() {
      // 实例化鼠标绘制工具
      this.drawingManager = new BMapGLLib.DrawingManager(this.mapInstance, {
        enableCalculate: true, // 绘制是否进行测距(画线时候)、测面(画圆、多边形、矩形)
        enableSorption: true,
        enableSorption: true, // 是否开启边界吸附功能
        sorptionDistance: 20, // 边界吸附距离
        enableGpc: true, // 是否开启延边裁剪功能
        enableLimit: true, // 是否开启超限提示
        // limitOptions: {
        //   area: 50000000, // 面积超限值
        //   distance: 30000,
        // },
        circleOptions: this.styleOptions, // 圆的样式
        polylineOptions: this.styleOptions, // 线的样式
        polygonOptions: this.styleOptions, // 多边形的样式
        rectangleOptions: this.styleOptions, // 矩形的样式
        labelOptions: labelOptions, // label的样式
      });
      this.drawingManager.addEventListener("overlaycomplete", () => {
        this.drawingManager._close();
        this.currentDrawType = false;
      });
      this.drawingManager.addEventListener(
        "markercomplete",
        this.markerComplete
      );
      this.drawingManager.addEventListener(
        "polylinecomplete",
        this.polylinecomplete
      );
      this.drawingManager.addEventListener(
        "polygoncomplete",
        this.polygoncomplete
      );
      this.drawingManager.addEventListener(
        "circlecomplete",
        this.circlecomplete
      );
      let zoomControl = new BMapGL.ZoomControl();
      this.mapInstance.addControl(zoomControl);
    },
    // 点完成绘制回调
    markerComplete(marker) {
      let _self = this;
      this.$Modal.info({
        render() {
          return (
            <div>
              <span>请输入覆盖物名称(或备注):</span>
              <br />
              <i-input on-input={(val) => (_self.newRemark = val)}></i-input>
            </div>
          );
        },
        onOk: () => {
          this.points.push({
            id: new Date().getTime().toString(),
            zIndex: this.zindex++,
            data: marker,
            name: this.newRemark || `新建坐标点${this.zindex - 1}`,
            type: "marker",
            zoom: this.mapInstance.getZoom(),
          });
          _self.newRemark = "";
        },
      });
      // this.points.push(marker);
      // marker.addEventListener("click", () => {
      //   let point = marker.getPosition();
      //   let label = new BMapGL.Label("删除", { position: point });
      //   label.setStyle({
      //     border: "none",
      //   });
      //   this.mapInstance.addOverlay(label);
      //   label.addEventListener("click", () => {
      //     this.mapInstance.removeOverlay(marker);
      //     this.mapInstance.removeOverlay(label);
      //     let index = this.points.findIndex((item) => item == marker);
      //     if (index < 0) throw new Error("要删除的点未有记录");
      //     this.points.splice(index, 1);
      //   });
      // });
    },
    // 线完成回调
    polylinecomplete(polyline) {
      let path = polyline.getPath();
      this.drawingManager.clearOverlays();
      let newpolyline = new BMapGL.Polyline(path, this.styleOptions);
      // this.lines.push(newpolyline);
      this.mapInstance.addOverlay(newpolyline);
      let _self = this;
      this.$Modal.info({
        render() {
          return (
            <div>
              <span>请输入覆盖物名称(或备注):</span>
              <br />
              <i-input on-input={(val) => (_self.newRemark = val)}></i-input>
            </div>
          );
        },
        onOk: () => {
          this.lines.push({
            id: new Date().getTime().toString(),
            zIndex: this.zindex++,
            data: newpolyline,
            name: this.newRemark || `新建线条${this.zindex - 1}`,
            type: "line",
            zoom: this.mapInstance.getZoom(),
          });
          _self.newRemark = "";
        },
      });
      // newpolyline.enableEditing();
      // newpolyline.addEventListener("click", (e) => {
      //   console.log(e);
      // });
    },
    // 多边形完成回调
    polygoncomplete(polygon) {
      let path = polygon.getPath();
      this.drawingManager.clearOverlays();
      let newpolygon = new BMapGL.Polygon(path, this.styleOptions);
      // this.polygons.push(newpolygon);
      // newpolygon.enableEditing();
      this.mapInstance.addOverlay(newpolygon);
      // this.mapInstance.addEventListener("click", (params) => {
      //   console.log(params);
      // });
      let _self = this;
      this.$Modal.info({
        render() {
          return (
            <div>
              <span>请输入覆盖物名称(或备注):</span>
              <br />
              <i-input on-input={(val) => (_self.newRemark = val)}></i-input>
            </div>
          );
        },
        onOk: () => {
          this.polygons.push({
            id: new Date().getTime().toString(),
            zIndex: this.zindex++,
            data: newpolygon,
            name: this.newRemark || `新建多边形${this.zindex - 1}`,
            type: "polygon",
            zoom: this.mapInstance.getZoom(),
          });
          _self.newRemark = "";
        },
      });
    },
    // 圆形
    circlecomplete(circle) {
      let center = circle.getCenter();
      let radius = circle.getRadius();
      this.drawingManager.clearOverlays();
      let newcircle = new BMapGL.Circle(center, radius, this.styleOptions);
      // this.circles.push(newcircle);
      // newcircle.enableEditing();
      this.mapInstance.addOverlay(newcircle);
      let _self = this;
      this.$Modal.info({
        render() {
          return (
            <div>
              <span>请输入覆盖物名称(或备注):</span>
              <br />
              <i-input on-input={(val) => (_self.newRemark = val)}></i-input>
            </div>
          );
        },
        onOk: () => {
          this.polygons.push({
            id: new Date().getTime().toString(),
            zIndex: this.zindex++,
            data: newcircle,
            name: this.newRemark || `新建圆形${this.zindex - 1}`,
            type: "circle",
            zoom: this.mapInstance.getZoom(),
          });
          _self.newRemark = "";
        },
      });
    },
  },
  mounted() {
    this.initMap().then(() => {
      this.addBoundary();
      this.addSearch();
      this.initDrawTool();
      this.initBackgroundPosition();
      this.initOverlays();
    });
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>
<style>
.tangram-suggestion-main {
  z-index: 9999;
}
</style>